var wpVars

function init() {
    wpVars = JSON.parse(hoist)
    
    tnsInstancing();    
    let CTA = new PhoneCTA();
    maskInstancing();

    if ( !helpers.isMobile ) {
        $('.main-menu li > a').on('click', handleAnchors);
    }

    let header = new Header();

    $('.open-budget').on('click', function() {
        $('.budget-overlay').fadeIn();
        helpers.lockBody();
    });

    $('.close-budget-overlay').on('click', function() {
        $('.budget-overlay').fadeOut();
        helpers.unlockBody();
    });

    $('form').on('submit', formHandling);

    window.innerWidth < 991 ? header.handleMenuMobile() : false

    let observer = lozad()
    observer.observe()
}

// window load binds 
window.onload = init;

function DOMLoaded() {
    // these are not always necessary but sometimes they fuck with ya
    if (helpers.iOS) {
        document.querySelector('html').classList.add('ios');
    } else if (helpers.IE()) {
        document.querySelector('html').classList.add('ie');
    }
}

// domcontent binds 
document.addEventListener('DOMContentLoaded', DOMLoaded);