// unique scripts go here.

const tnsInstancing = () => {
	let phoneSlider = tns({
		container: '.phone-carousel-body',
		mouseDrag: true,
		controls: false,
		nav: false,
		speed: 1000,
		autoplay: true,
		autoplayHoverPause: true,
		autoplayButtonOutput: false,
		autoplayTimeout: 4000,
		loop: true,
		responsive: {
			0: {
				items: 1,
				gutter: 40,
				center: true,
			},
			1000: {
				items: 4,
				gutter: 30,
			},
			1700: {
				items: 5,
				gutter: 0
			}
		}
	})

	// let mediaSlider = tns({
	//     container: '.appolar-media-gallery > div',
	//     items: 5,
	//     gutter: 20,
	//     loop: true,
	//     controls: false,
	//     nav: false,
	//     mouseDrag: true,
	//     autoplay: true,
	//     autoplayTimeout: 4000,
	//     autoplayButtonOutput: false
	// })
}


/**
 * @method maskInstancing
 * @uses jquery.mask.js <- on src/js/d.mask.js
 *
 * Basic form field masks
 */

function maskInstancing() {
	var SPMaskBehavior = function (val) {
			return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
		},
		spOptions = {
			onKeyPress: function (val, e, field, options) {
				field.mask(SPMaskBehavior.apply({}, arguments), options);
			}
		};

	$('input[name="Telefone"], input[name="Celular"], input[name="Telefone Fixo"]').mask(SPMaskBehavior, spOptions);
	$('input[name="Data"], input[name="Data de Nascimento"]').mask('00/00/0000');
	$('input[name="Horario"], input[name="Hora"]').mask('00:00');

}

const handleAnchors = (e) => {
	let target = e.target
	let attr = target.getAttribute('href')
	let scrollTo = document.getElementById(attr.replace('#', ''));

	attr.indexOf('#') !== -1 ? e.preventDefault() : false

	$('html, body').animate({
		scrollTop: $(attr).offset().top - 350
	}, 500);

	if (helpers.isMobile) {
		$('.no-scroll').removeClass('no-scroll');
		$('.is-open').removeClass('is-open');
		$('.menu-open').removeClass('menu-open');
		$('.is-active').removeClass('is-active');
	}

}


/**
 *  Cookie related stuff
 * @param {String} name
 * @param {String} value
 * @param {Int} days
 */

function setCookie(name, value, days) {
	var expires = "";
	if (days) {
		var date = new Date();
		date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
		expires = "; expires=" + date.toUTCString();
	}
	document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

function getCookie(name) {
	var nameEQ = name + "=";
	var ca = document.cookie.split(';');
	for (var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) == ' ') c = c.substring(1, c.length);
		if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
	}
	return null;
}

function eraseCookie(name) {
	document.cookie = name + '=; Max-Age=-99999999;';
}



/**
 * @method formHandling
 * @see https://developer.mozilla.org/en-US/docs/Web/API/FormData/Using_FormData_Objects
 * @param evt Event handler
 *
 */

function formHandling(evt) {
	evt.preventDefault();
	var formData = $(this).serializeObject();
	var button = $(this).find('button');
	var oldTxt = button.text();
	button.addClass('btn-disabled loading');

	formData['Minutos Restantes:'] = getCookie('userMinutes');
	formData['Segundos Restantes:'] = getCookie('userSeconds');
	formData['action'] = 'ap_submit_emails'

	$.ajax({
		type: 'POST',
		url: wpVars.adminURL,
		data: formData,
		success: function (response) {
			Sweetalert2.fire(
				'Obrigado!',
				'Seu E-mail foi enviado com sucesso! <br> Nossos analistas retornarão o mais breve possível.',
				'success'
			)
			button.removeClass('btn-disabled loading')
			ga('gtag_UA_140230513_33.send', 'event', 'Formulário', 'Envio', 'Formulário');
			// XCL.track(formData);
		},
		fail: function () {
			Sweetalert2.fire(
				'Ops!',
				'Houve um problema com o envio do seu E-mail. <br> Por favor tente novamente mais tarde ou entre em contato através de um dos nossos telefones.',
				'error'
			)
			button.removeClass('btn-disabled loading')

		},
		always: function () {
			button.removeClass('btn-disabled loading')
		}
	});
}

// const makeCounterFixed = () => {
//     let y = pageYOffset; 

//     if ( y > 100 ) {
//         document.querySelector('.header-counter').classList.add('fixed');
//     }
// }