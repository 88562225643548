class Header {
    constructor(settings) {

        const defaultSettings = {
            headerElement: document.getElementById('masthead'),
            fixedHeaderOffset: 30,
            menuButton: document.querySelector('.hamburger'),
            menuElement: document.querySelector('.main-menu'),
            subMenuElements: document.querySelectorAll('#masthead .menu-item-has-children'),
            closeSubMenuButton: document.querySelector('.close-submenu'),
            debug: false,
            logoNode: document.querySelector('.header-logo'),
            logoImageNode: document.querySelector('.header-logo img'),
            counterElement: document.querySelector('.header-counter'),
        }

        // set default args if none are provided
        // s = settings
        arguments.length < 1 ? this.s = defaultSettings : this.s = settings;

        // log settings if debug is true
        !!this.s.debug ? this.logSettings() : null;

        // listener to hamburger button click
        // this.s.menuButton.addEventListener('click', this.toggleMenu.bind(this))

        // // Submenu listeners
        // this.s.subMenuElements.forEach( submenu => {
        //     submenu.addEventListener('click', this.openSubMenu.bind(this));
        // })

        // // Submenu close button listener
        // this.s.closeSubMenuButton.addEventListener( 'click', this.closeSubMenu.bind(this) );

        // fixed menu
        // window.onscroll = this.becomeFixed.bind(this);
        // this.becomeFixed();
    }

    logSettings() {
        console.log(this.s);
    }

    handleMenuMobile() {
        let hamb = document.querySelector('.hamb')
        let menu = document.querySelector('.main-menu')
        let itens = document.querySelectorAll('.menu-item:not(.has-submenu)')
        let submenus = document.querySelectorAll('.submenu')
        let voltar = document.querySelectorAll('.voltar')
    
        hamb && menu ? (
            hamb.addEventListener('click', () => {
                hamb.classList.contains('is-open') && submenus ? (
                    submenus.forEach(submenu => {
                        submenu.classList.remove('is-open')
                    })
                ) : false
    
                hamb.classList.toggle('is-open')
                menu.classList.toggle('is-open')
            })
        ) : false

        itens && itens.forEach(item => {
            item.addEventListener('click', e => {
                let href = e.target.getAttribute('href')
                if( href.indexOf('#') !== -1 ) {
                    e.preventDefault()
                    let target = document.querySelector(href)
                    window.scrollTo({
                        top: target.offsetTop - 100,
                        behavior: 'smooth'
                    })
                    hamb.classList.toggle('is-open')
                    menu.classList.toggle('is-open')
                }
            })
        })
    
        submenus ? (
            submenus.forEach(submenu => {
                let btn = submenu.parentNode
    
                btn.addEventListener('click', e => {
                    console.log(e.target);
                    if( e.target.classList.contains('has-submenu') ) {
                        e.preventDefault()
                        submenu.classList.add('is-open')
                    }
                })
            })
        ) : false
    
        voltar ? (
            voltar.forEach(btn => {
                let submenu = btn.parentNode
                btn.addEventListener('click', () => {
                    submenu.classList.remove('is-open')
                })
            })
        ) : false
    }

    // toggleMenu() {

    //     this.s.menuButton.classList.toggle('is-active');
    //     this.s.menuElement.classList.toggle('is-open');
    //     this.s.headerElement.classList.toggle('menu-open');

    //     document.querySelectorAll('body, html').forEach(item => {
    //         item.classList.toggle('no-scroll');
    //     });

    //     if (this.s.headerElement.classList.contains('submenu-open')) {
    //         this.s.headerElement.classList.remove('submenu-open');
    //     }

    // }

    // openSubMenu(event) {

    //     // get all children
    //     const childNodes = event.target.childNodes

    //     // create array and filter it, returning only the list
    //     let childList = Array.prototype.slice.call(childNodes).filter(node => {
    //         return node.nodeName === 'UL';
    //     });

    //     // define first item of array as list
    //     childList = childList[0];

    //     // class handling
    //     childList.classList.add('is-open');
    //     this.s.headerElement.classList.toggle('submenu-open');
    // }

    // closeSubMenu() {
    //     this.s.headerElement.classList.remove('submenu-open');
    //     const openItems = document.querySelectorAll('.sub-menu.is-open');

    //     openItems.forEach(openItem => {
    //         openItem.classList.remove('is-open');
    //     });
    // }

    becomeFixed() {
        if ( helpers.isMobile ) {
            if ( pageYOffset > 100 )  {
                this.s.counterElement.classList.add('fixed');
            } else {
                this.s.counterElement.classList.remove('fixed');
            }
        }
    }
}
